export default function SvgLogo() {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 10.24 10.24"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m 10.239992,5.1199999 a 0.83817249,0.83817249 0 0 1 -0.553334,0.793633 l -2.75722,1.015813 -1.015813,2.75722 a 0.84581033,0.84581033 0 0 1 -1.587265,0 L 3.310547,6.9294459 0.55332604,5.9136329 a 0.84581033,0.84581033 0 0 1 0,-1.587266 L 3.310547,3.3105544 4.32636,0.55333405 a 0.84581033,0.84581033 0 0 1 1.587265,0 l 1.015813,2.75722035 2.75722,1.0158125 a 0.83817249,0.83817249 0 0 1 0.553334,0.793633 z" />
    </svg>
  )
}
